import { render, staticRenderFns } from "./npwp-photo.vue?vue&type=template&id=fbf5c550&scoped=true"
import script from "./npwp-photo.vue?vue&type=script&lang=js"
export * from "./npwp-photo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbf5c550",
  null
  
)

export default component.exports